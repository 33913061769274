import {React,useState, useEffect,useRef} from "react";
import {useNavigate} from "react-router-dom"
import Layout from "./layout";
import emailjs from '@emailjs/browser';

function Event_Form (){

    const navigate = useNavigate();
    const initialValues = {
        first_name: "",
        last_name: "",
        mobile: "",
        email: "",
        dateofbirth:"",
        occupation: "",
        companyname: "",
        food: "",
        city: "",
        previous_visit: "",
        amount: null,
    };

    const form = useRef();

    const[formValues, setformValues] = useState(initialValues);
    const[formErrors, setformErrors] = useState({});
    let message = 'For seamless registration, please keep the window open until the "Registration Confirmation" message appears. Thank you!"'


    const handleChange = (e) => {
        const {name,value} = e.target;
        setformValues ({...formValues, [name] : value});
    }

    
    //  const rzpKey = "rzp_test_N77BUviHwyHENt";
     const rzpKey = "rzp_live_mIJgSBd1NGY26X";

     useEffect(() => {
        if(formValues.food === "Food"){
            setformValues((prevValues) => ({
                ...prevValues, amount: 1499
            }))
        } else if(formValues.food === "Food & Cocktails"){
            setformValues((prevValues) => ({
                ...prevValues, amount: 2999
            }))
        }
     }, [formValues.food])

    const handlePayment= async (e)=>{
        e.preventDefault();
        setformErrors(validate(formValues));

        if(Object.keys(validate(formValues)).length === 0){
            console.log(formValues);    
            alert(message);
            precautionEmail();

            const options = {
                key: rzpKey,
                amount: formValues.amount * 100, 
                currency: "INR",
                name: "Transaction Details",
                description: 'Event Registration Payment',
                image:
                  "https://womennetworkignclub.azurewebsites.net/static/images/wnc-logo.JPG",
                  handler: function (response) {
                    console.log("Handler called!")

                    handleGoogleSheet({
                        ...formValues,payment_key:response.razorpay_payment_id
                    })
                  },
                  prefill: {
                    name: formValues.first_name,
                    email: formValues.email,
                    contact: formValues.mobile
                },
                notes: {
                  address: "some address",
                },
                theme: {
                  color: "#6a3669",
                },
              };
          
              let rzp = new window.Razorpay(options);
              rzp.open();
              
        }
    }

    const precautionEmail = () =>{
        //template-ID: template_j6zgole
        //service-ID: service_jvttd72
        //public-key: hhvaZ8tXKLqxY8JXM

        emailjs
          .send('service_jvttd72', 'template_j6zgole', formValues,{
            publicKey: "hhvaZ8tXKLqxY8JXM",
          })
          .then(
            () => {
              console.log('SUCCESS!');
            },
            (error) => {
              console.log('FAILED...', error.text);
            },
        );
    }

    const sendEmail = () => {

        //service-ID: service_jvttd72,
        //template-ID: template_fa71euq,
        //public - key: hhvaZ8tXKLqxY8JXM

        emailjs
          .sendForm('service_jvttd72', 'template_fa71euq', form.current, {
            publicKey: 'hhvaZ8tXKLqxY8JXM',
          })
          .then(
            () => {
              console.log('SUCCESS!');
            },
            (error) => {
              console.log('FAILED...', error.text);
            },
        );
    };


    const validate = (values) =>{
        const errors = {};
        const regex =  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if(!values.first_name){
            errors.first_name = "Please enter your First Name"
        }

        if(!values.last_name){
            errors.last_name = "Please enter your Last Name"
        }

        if (!values.email) {
            errors.email = "Email ID is required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
          ) {
            errors.email = "Please enter a valid E-Mail ID";
          }

        if(!values.mobile){
            errors.mobile = "Please enter your mobile number";
        } else if(values.mobile.length !=10){
            errors.mobile = "Please enter a valid number";
        }

        if(!values.dateofbirth){
            errors.dateofbirth= "Please enter your Date of Birth"
        }

        if(!values.occupation){
            errors.occupation= "Please select your Occupation";
        }
        
        if(!values.companyname && formValues.occupation === "Entreprenuer"){
            errors.companyname = "Please enter your company name";
        }

        if(!values.food){
            errors.food = "Please select your food preference";
        }

        if(!values.city){
            errors.city = "Please enter your city name";
        }

        if(!values.previous_visit){
            errors.previous_visit = "Please select an option";
        }
        return errors;
    }
    const handleGoogleSheet = (obj) => {
            
            fetch(
            "https://script.google.com/macros/s/AKfycbwK-EDk3Xkf9TAiuPk1yOi3NGKewn1Rq0LKctqYSnEZ0jA2E-8-RabCdeOQkJ8ImXDMyQ/exec",
            {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams(obj).toString(), // Correctly encode form data
            }
             )
            .then((res) => res.text(console.log("response", res)))
            .then((data) => {
            console.log("data", data);
            
            })
            .catch((error) => {
            console.log("error", error);
            });
            sendEmail(); //registration-confirmation mail
            navigate("/thank-you")
            setformValues(initialValues);

        };

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
      }, []);



    return (
            <div className="bg-grey ">
                <div className="max-w-[760px]  bg-white px-16 py-9  ">
                    <h1 className="font-[Poppins]  text-4xl uppercase font-semibold text-center block sm:hidden"> Anniversary</h1>
                    <h1 className="font-[Poppins]  text-2xl uppercase font-semibold text-center sm:block hidden"> Anniversary Event - Registration Form</h1>
                    <h3 className="font-[Poppins]  text-lg text-[#6A6A6A] uppercase font-semibold text-center mt-3 sm:hidden block">Registration Form</h3>

                    <form ref={form} onSubmit={handlePayment}>
                        <div className="grid grid-cols-1 px-2 sm:px-0 sm:grid-cols-2 mt-7 gap-y-5 items-center sm:items-start ">

                            <div className="flex flex-col w-full">
                                <label className="event-form-label">First Name </label>
                                <input 
                                    type="text"
                                    placeholder="Mary"
                                    className="form-input "
                                    name="first_name"
                                    value={formValues.first_name}
                                    onChange={handleChange}
                                />
                                <p className="form-error">{formErrors.first_name}</p>
                                        
                            </div>

                            <div>
                                <label className="event-form-label">Last Name </label>
                                <input 
                                    type="text"
                                    placeholder="Smith"
                                    className="form-input"
                                    name="last_name"
                                    value={formValues.last_name}
                                    onChange={handleChange}
                                />

                                <p className="form-error">{formErrors.last_name}</p>
                            </div>

                            <div>
                                <label className="event-form-label">Phone Number </label>
                                <input 
                                    type="tel"
                                    placeholder="09876 54321"q
                                    className="form-input"
                                    name="mobile"
                                    value={formValues.mobile}
                                    onChange={handleChange}
                                />
                                <p className="form-error">{formErrors.mobile}</p>
                            </div>

                            <div>
                                <label className="event-form-label">E-Mail Address</label>
                                <input 
                                    type="email"
                                    placeholder="abc@gmail.com"
                                    className="form-input"
                                    name="email"
                                    value={formValues.email}
                                    onChange={handleChange}
                                />
                                <p className="form-error">{formErrors.email}</p>
                            </div>

                            <div>
                                <label className="event-form-label">Date of Birth </label>
                                <input 
                                    type="date"
                                    placeholder="John Doe"
                                    className="form-input"
                                    name="dateofbirth"
                                    value={formValues.dateofbirth}
                                    onChange={handleChange}
                                />
                                <p className="form-error">{formErrors.dateofbirth}</p>
                            </div>

                            <div>
                                <label className="event-form-label">Occupation </label>

                                <select className="dropdown-select" defaultValue="-Select an Option-" name="occupation" onChange={handleChange} >
                                    <option disabled>-Select an Option-</option>
                                    <option value="Entreprenuer">Entreprenuer</option>
                                    <option value="Freelancer">Freelancer</option>
                                    <option value="Home Maker">Home Maker</option>
                                    <option value="Influencer">Influencer</option>
                                    <option value="Professional">Professional</option>
                                    <option value="Aspiring Entreprenuer">Aspiring Entreprenuer</option>
                                    <option value="None of the above">-None of the above-</option>

                                </select>

                                <p className="form-error">{formErrors.occupation}</p>
                            </div>

                            <div className={formValues.occupation === "Entreprenuer" ? "flex flex-col" : "hidden"}>
                                <label className="event-form-label">Company Name</label>

                                <input 
                                    type="text" 
                                    placeholder="Organisation Name"
                                    className="form-input" 
                                    name="companyname"
                                    onChange={handleChange}
                                    value={formValues.companyname}
                                />
                                <p className="form-error">{formErrors.companyname}</p>
                            </div>

                            <div className="flex flex-col">
                                <label className="event-form-label">Food Preference</label>

                                <select className="dropdown-select" defaultValue="-Select an Option-" name="food" onChange={handleChange}  >
                                    <option disabled>-Select an Option-</option>
                                    <option value={"Food"}>Food</option>
                                    <option value={"Food & Cocktails"}>Food & Cocktails</option>
                                </select>
                                <p className="form-error">{formErrors.food}</p>
                            </div>

                            <div className="flex flex-col">
                                <label className="event-form-label">Your City Name</label>

                                <input 
                                        type="text"
                                        className="form-input"
                                        placeholder="Coimbatore"
                                        name="city"
                                        value={formValues.city}
                                        onChange={handleChange}
                                />
                                <p className="form-error">{formErrors.city}</p>
                            </div>

                            <div className="flex flex-col gap-y-4 sm:gap-y-3">
                                <label className="event-form-label">Have you been to a WNC event before?</label>

                                <div className="flex flex-row  gap-x-7">
                                    <button type="button" name="previous_visit" value = "Yes" onClick={handleChange} className={`font-[Poppins] text-[16px] border-[#CCC] border-[1px] rounded-sm py-1 px-5 text-black ${formValues.previous_visit === "Yes" ? "!text-white !bg-black !border-none":""}`}>Yes</button>
                                    <button type="button" name="previous_visit" value = "No" onClick={handleChange} className={`font-[Poppins] text-[16px] border-[#CCC] border-[1px] rounded-sm py-1 px-5 text-black ${formValues.previous_visit === "No" ? "!text-white !bg-black !border-none":""}`}>No</button>
                                </div>

                                <p className="form-error ">{formErrors.previous_visit}</p>
                            </div>

                        </div>
                        <hr className="border-t-[1px] border-[#8B8B8C] w-full mt-5 " />
                        <div className="flex flex-row items-center gap-x-20 flex-wrap sm:flex-nowrap gap-y-5 sm:gap-y-0">
                            <div>
                                <h5 className="mt-7 font-[Poppins] text-[16px] text-[#6A6A6A]"> <span className="text-red text-[16px]">*</span> All the above fields are mandatory</h5>
                                <h5 className="mt-3 font-[Poppins] text-[16px] text-[#6A6A6A]"> <span className="text-red text-[16px]">*</span> Registration fee is not refundable</h5>
                            </div>

                            <h5 className={formValues.amount != null ? "font-[Poppins] text-[18px] mt-5" : "hidden"}>Total Payable Amount : <span className="font-normal">₹ {formValues.amount}</span></h5>
                        </div>
                            
                        <button type="submit" className=" mt-12 font-[Poppins] bg-purple px-7 py-2 text-white uppercase sm:w-1/5 mx-auto ">Pay</button>
                    </form>

                    
                </div>
            </div>

    )
}


export default Event_Form;