import React from "react";
import { Link } from "react-router-dom";

import Layout from "../Components/layout"
import Event_Form from "../Components/Event_Form"
import location from "../images/Event_Page/location.png"
import event_image from "../images/Event_Page/group_1.png"
import event_image1 from "../images/Event_Page/event_image1.png"
import attendees from "../images/Event_Page/Participants.png"
import time from "../images/Event_Page/time.png"
import map_pin from "../images/Event_Page/map_pin.png"
import arrow from "../images/Event_Page/arrow.png"
import gamechanging from "../images/Event_Page/gamechanging_icon.png"
import galore from "../images/Event_Page/galore_icon.png"
import cocktail_icon from "../images/Event_Page/cocktail_icon.png"
import buildnetwork from "../images/Event_Page/buildnetwork_icon.png"
import music from "../images/Event_Page/music_icon.png"
import comedy from "../images/Event_Page/comedy_icon.png"

import tune from "../images/Event_Page/sponsor_icons/tune.png"
import moc from "../images/Event_Page/sponsor_icons/moc.png"
import at47 from "../images/Event_Page/sponsor_icons/at_47.png"
import deflor from "../images/Event_Page/sponsor_icons/de_flor.png"
import rao from "../images/Event_Page/sponsor_icons/rao.png"
import mahaveers from "../images/Event_Page/sponsor_icons/mahaveers.png"
import landsandlands from "../images/Event_Page/sponsor_icons/logo_black.png"
import purple_percentage from "../images/Event_Page/percentage_icon_purple.png"
import yellow_precentage from "../images/Event_Page/percentage_icon_yellow.png"



function Event_Page(){
    return(
        <Layout>
            {/* Banner Section - Larger Screens */}
            <div className="event_banner sm:block hidden">
                <div className="max-w-7xl mx-auto py-20">
                        <div className="flex flex-col sm:flex-row items-center justify-between mt-32">
                            {/* <div>
                                <h1 className="font-[Raleway] text-[40px] sm:text-[50px] font-medium text-white w-[617px] leading-[65px]">WNC <span className="font-bold bg-yellow text-black">SheNergy</span> <br/>Coimbatore's Biggest Networking event</h1>
                                <p className="font-[Poppins] text-[18px] text-white w-[520px] mt-7">A fusion of "she" and "energy," representing the power, strength, and vibrancy that women collectively possess. </p>
                            </div> */}

                            <div>
                                <h3 className="font-[Poppins] text-[30px] font-bold text-white bg-purple px-3 w-[180px] text-center">SHENERGY</h3>
                                <h1 className="uppercase font-[Raleway] text-[60px] text-yellow font-extrabold leading-[80px]"> COIMBATORE's Biggest Networking Event</h1>
                                <p className="font-[Poppins] text-[25px] text-white w-[520px] mt-3">Connect with 250+ Incredible Women </p>
                            </div>

                            <div className="bg-white w-[700px] h-[300px] ">
                                <div className="flex flex-row justify-between items-center p-5">
                                    <div>
                                        <h5 className="font-[Raleway] text-[18px] text-[#8B8B8C]">Start</h5>
                                        <p className="font-[Poppins] text-[18px] text-black">11:00 AM</p>
                                    </div>
                                    <div className="flex flex-col gap-y-0 items-center">
                                        <h5 className="font-[Poppins] text-[16px] text-[#8B8B8C] line-height-[0px]">FEB</h5>
                                        <h3 className="font-[Poppins] text-[50px] text-black">09</h3>
                                        <h5 className="font-[Poppins] text-[16px] text-[#8B8B8C]">SUNDAY</h5>
                                    </div>
                                    <div>
                                        <h5 className="font-[Raleway] text-[18px] text-[#8B8B8C] text-right">End</h5>
                                        <p className="font-[Poppins] text-[18px] text-black">04:00 PM</p>
                                    </div>
                                </div>
                                <hr className="w-[100%] border-t-[0.8px] border-[#8B8B8C] mt-3" />

                                <div className="w-full bg-[#F5F5F5] px-3 py-7">
                                    {/* <div className="flex flex-row items-center gap-x-3 px-5 mb-3">
                                        <img src={yellow_precentage} alt="yellow_percentage" className="w=[30px] h-[30px]" />
                                        <p className="text-[#FFA412] font-semibold font-[Poppins]">Early bird offer ends by 31-Dec-2024</p>
                                    </div> */}
                                    <div className="flex flex-row gap-x-1 items-center">
                                        <div className="flex flex-row items-center justify-start gap-x-5">
                                            <img src={location} alt="location-icon" />
                                            <div className="flex flex-col items-start gap-y-1">
                                                <h1 className="font-[Raleway] font-bold text-[16px] leading-[26px]">The Living Room </h1>
                                                <h3 className="font-[Raleway] font-normal leading-[22px] text-[14px] text-[#8B8B8C]">Nava India, Coimbatore</h3>
                                                <a href="https://maps.app.goo.gl/xoZhyVjDSsYgenLeA" target="_blank"><button className="font-[Raleway] text-blue text-[14px] underline">View Map</button></a>
                                            </div>
                                        </div>

                                        <hr className="rotate-[-90deg] border-t-[0.8px] border-[#8B8B8C] w-[70px]" />

                                        <div className="flex flex-col ">
                                            <h5 className="font-[Poppins] font-medium text-[12px] leading-[26px] text-[#8B8B8C]">From</h5>
                                            <h3 className="font-[Poppins] text-purple text-[30px] font-semibold">₹ 1499</h3>
                                        </div>

                                    </div>

                                    <div className="flex flex-row items-center mt-7 justify-center gap-x-9">
                                        <a href="#event_info"><button className="bg-purple text-white font-[Poppins] text-[16px] font-medium uppercase w-[175px] h-[42px] rounded-[5px]">Know More</button></a>
                                        <Link to={"/event-register"}><button className="bg-purple text-white font-[Poppins] text-[16px] font-medium uppercase w-[175px] h-[42px] rounded-[5px]" onClick={Top}>Register Now</button></Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                </div>
            </div>

            {/* Mobile Banner */}
            
            <div className="max-w-[600px] event_banner sm:hidden flex flex-col items-center">
                <h2 className="font-[Raleway] font-bold text-[20px] tracing-[4%] bg-purple px-3 py-1 w-fit text-white mx-auto">SHENERGY</h2>

                <h1 className="font-[Raleway] font-semibold text-[32px] leading-[44px] text-white uppercase w-[378px] text-center mt-7">Coimbatore's Biggest Networking Event</h1>
                <h5 className="font-[Poppins] text-[16px] font-medium leading-[24px] text-center w-[300px] text-[#D9D9D9] mt-3">Connect with 150+ Incredible Women</h5>

                <div className="bg-white rounded-[10px] flex flex-row gap-x-3 items-center px-3 py-5 w-[350px] mt-7 relative">
                    <div className="bg-purple rounded-[10px] flex flex-col items-center text-white justify-between w-[75px] h-[89px] py-2">
                        <h5 className="font-[Poppins] text-[12px] uppercase ">Feb</h5>
                        <h4 className="font-[Poppins] text-[24px] font-semibold ">9</h4>
                        <h5 className="font-[Poppins] text-[12px] uppercase ">2025</h5>
                    </div>

                    <div className="flex flex-col gap-y-1">
                        {/* <div className="flex flex-row items-center gap-x-1 absolute top-1 right-5">
                            <img src={purple_percentage} alt="purple_percentage" className="w-[15px] h-[15px]" />
                            <h5 className="font-[Poppins] text-[12px] font-medium text-purple">Early bird offer ends by 31-Dec-2024</h5>
                        </div> */}
                        <h5 className="font-[Raleway] text-[16px] font-bold leading-[26px] text-[263238]">The Living Room Coimbatore </h5>
                        <h5 className="font-[Raleway] text-[14px] leading-[22px] text-[8B8B8C]">Nava India, Coimbatore</h5>
                        <h5 className="font-[Poppins] text-[12px] leading-[26px] font-medium text-[263238]">11:00 AM to 04:00 PM</h5>
                    </div>
                </div>

                <Link to="/event-register"><button className="bg-[#FFA412] rounded-sm text-white w-[144px] p-1 uppercase font-[Poppins] font-semibold text-[15px] mt-5">Register Now</button></Link>


            </div>
            
            {/* Milestones */}

            {/* <div className="max-w-5xl w-[85%] sm:w-full mx-auto bg-white shadow-2xl border-[0.8px] border-black rounded-lg py-7 px-20 my-20">
                <div className="flex flex-col sm:flex-row gap-y-7 sm:items-center sm:justify-between">
                    <div className="flex flex-col items-center ">
                        <h1 className="font-[Poppins] text-[120px] leading-[120px]">80</h1>
                        <p className="font-[Poppins] text-[20px] font-semibold text-[#263238]">People</p>
                    </div>

                    <hr className="border-[0.8px] bordebackground-image: url(/src/images/Event_Page/Banner_image.png)-[#57595A] w-full sm:w-[60px] sm:rotate-[-90deg]" />

                    <div className="flex flex-col items-center ">
                        <h1 className="font-[Poppins] text-[120px] leading-[120px]">07</h1>
                        <p className="font-[Poppins] text-[20px] font-semibold text-[#263238]">Events Conducted</p>
                    </div>

                    <hr className="border-[0.8px] border-[#57595A] w-full sm:w-[60px] sm:rotate-[-90deg]" />

                    <div className="flex flex-col items-center ">
                        <h1 className="font-[Poppins] text-[120px] leading-[120px]">08</h1>
                        <p className="font-[Poppins] text-[20px] font-semibold text-[#263238]">Years</p>
                    </div>
                </div>
            </div> */}




            {/* Sponsors Section */}
            <div className="bg-grey">
                <div className="max-w-7xl mx-auto py-20">
                    {/* <h4 className="font-[Poppins] text-[15px] text-center">presenting</h4> */}
                    <h1 className="font-[Poppins] text-[30px] text-[#262626] text-center uppercase font-bold">Our <span className="italic">Sponsors</span></h1>
                    <hr className="border-t-2 border-purple w-28 my-3 mx-auto" />

                    <div className="flex flex-col gap-y-5">
                        <div className="flex flex-col items-center">
                            <h3 className="font-[Raleway] text-[20px] font-bold text-black text-center px-1 uppercase mt-5 mb-3 underline decoration-purple">Title Sponsor</h3>
                            <img src={tune} alt="tune_logo" className="w-[200px] h-[120px]" />
                        </div>

                        <div className="flex flex-col items-center">
                            <h3 className="font-[Raleway] text-[20px] font-bold text-black  text-center px-1 uppercase my-7 underline decoration-purple">Co-Sponsors</h3>

                            <div className="flex flex-row gap-x-5 items-center">
                                <img src={rao} alt="rao_logo" className="w-[150px]" />
                                <img src={at47} alt="at-47_logo" className="w-[160px] " />
                            </div>
                        </div>
                        
                        <div className="flex flex-col items-center">
                            <h3 className="font-[Raleway] text-[20px] font-bold text-black text-center px-1 uppercase mt-5 mb-3 underline decoration-purple">Platinum Sponsors</h3>
                            <div className="flex flex-row items-center sm:gap-x-16 gap-x-4 mt-3 justify-center">
                                <img src={mahaveers} alt="tune_logo" className="w-[200px] h-[70px] sm:w-[236px] sm:h-[90px]" />
                                <img src={landsandlands} alt="l&l_logo" className="w-1/3 sm:w-1/4" />
                            </div>
                        </div>


                        <div className="flex flex-col items-center">
                            <div className="flex flex-row items-center gap-x-10">
                                <div className="flex flex-col items-center">
                                    <h3 className="font-[Raleway] text-[16px] font-bold text-black  text-center px-1 uppercase my-7 underline decoration-purple">Community Partner</h3>
                                    <img src={moc} alt="at-47_logo" className="w-[130px] " />
                                </div>

                                <div className="flex flex-col items-center">
                                    <h3 className="font-[Raleway] text-[16px] font-bold text-black  text-center px-1 uppercase mb-7 underline decoration-purple">Decor Partner</h3>
                                    <img src={deflor} alt="at-47_logo" className="w-[150px] " />
                                </div>

                            </div>
                        </div>

                        {/* <div> */}

                    </div>
                </div>
            </div>

            
            {/* What to Expect Section  */}

            <div className="max-w-7xl mx-auto  py-12">
                <h1 className="font-[Poppins] text-[30px] text-[#262626] text-center uppercase font-bold">What to <span className="italic">Expect?</span></h1>
                <hr className="border-t-2 border-purple w-28 my-3 mx-auto" />

                <div className="grid grid-cols-1 sm:grid-cols-2 mt-12 gap-y-16 px-5 sm:px-0 ">
                    <div className="flex flex-row items-start justify-center gap-x-9">
                        <img src={buildnetwork} alt="build_network" className="w-[93px] h-[93px]" />
                        <div className="">
                            <h2 className="font-[Raleway] text-[20px]  sm:text-[28px] font-normal text-[#262626]">Build Your Network</h2>
                            <p className="font-[Poppins] text-[16px] sm:text-[16px] font-normal text-[#57595A] sm:w-[450px] mt-3">Meet 250+ incredible women - entreprenuers, leaders, creators and professionals - who are crushing it.</p>
                        </div>

                    </div>

                    <div className="flex flex-row items-start justify-center gap-x-9">
                        <img src={gamechanging} alt="gamechanging" className="w-[93px] h-[93px]" />
                        <div className="">
                            <h2 className="font-[Raleway] text-[20px]  sm:text-[28px] font-normal text-[#262626]">Game-Changing Stories</h2>
                            <p className="font-[Poppins] text-[16px] sm:text-[16px] font-normal text-[#57595A] sm:w-[450px] mt-3">Hear from trailblazing women who've been there, done that and want to help you do it too.</p>
                        </div>

                    </div>

                    <div className="flex flex-row items-start justify-center gap-x-9">
                        <img src={galore} alt="stories_icon" className="w-[93px] h-[93px]" />
                        <div className="">
                            <h2 className="font-[Raleway] text-[20px]  sm:text-[28px] font-normal text-[#262626]">Opportunities Galore</h2>
                            <p className="font-[Poppins] text-[16px] sm:text-[16px] font-normal text-[#57595A] sm:w-[450px] mt-3">Discover collaborations, form partnerships and connect with like-minded women.</p>
                        </div>

                    </div>


                    <div className="flex flex-row items-start justify-center gap-x-9">
                        <img src={music} alt="music_icon" className="w-[93px] h-[93px]" />
                        <div className="">
                            <h2 className="font-[Raleway] text-[20px]  sm:text-[28px] font-normal text-[#262626]">Feel the Beat</h2>
                            <p className="font-[Poppins] text-[16px] sm:text-[16px] font-normal text-[#57595A] sm:w-[450px] mt-3">Immerse yourself in the energy of live music performances that bring the event to life.</p>
                        </div>
                    </div>

                    <div className="flex flex-row items-start justify-center gap-x-9">
                        <img src={cocktail_icon} alt="cocktail_icon" className="w-[93px] h-[93px]" />
                        <div className="">
                            <h2 className="font-[Raleway] text-[20px]  sm:text-[28px] font-normal text-[#262626]">Brunch, Cocktails & Conversations</h2>
                            <p className="font-[Poppins] text-[16px] sm:text-[16px] font-normal text-[#57595A] sm:w-[450px] mt-3">Enjoy a delicious brunch, sip on refreshing cocktails, and dive into meaningful conversations.</p>
                        </div>

                    </div>

                    <div className="flex flex-row items-start justify-center gap-x-9">
                        <img src={comedy} alt="comedy_icon" className="w-[93px] h-[93px]" />
                        <div className="">
                            <h2 className="font-[Raleway] text-[20px]  sm:text-[28px] font-normal text-[#262626]">Comedy with a Twist</h2>
                            <p className="font-[Poppins] text-[16px] sm:text-[16px] font-normal text-[#57595A] sm:w-[450px] mt-3">Enjoy a dose of laughter with a special stand-up comedy performance to keep the mood light and fun.</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Event Information Section - Above Mobile*/}

            <div id="event_info" className="bg-grey ">
                <div className="max-w-6xl mx-auto py-32 sm:block hidden">
                    <div className="flex flex-row justify-center">
                        <div className="sm:w-[407px] w-full relative">
                            <img src={event_image1} alt="event_group_image" className="w-full sm:h-[600px] h-[300px] relative " />
                            <div className="bg-black hidden sm:block absolute w-[369px] bottom-0 p-5">
                                <div className="flex flex-row items-center  justify-between w-[300px]">
                                    <div>
                                        <p className="text-[18px] text-white  font-[Poppins]  font-semibold">Food Only</p>
                                        <p className="text-[#D9D9D9] text-[12px] font-[Raleway] text-center ">(Unlimited buffet for 3 hrs)</p>
                                    </div>
                                    <p className="font-[Poppins] text-white text-[18px] font-semibold">₹ 1499</p>
                                </div>

                                <div className="flex flex-row items-center justify-between w-[300px] mt-5">
                                    <div>                            
                                        <p className="text-[18px] text-white font-[Poppins]  font-semibold">Food & Cocktails</p>
                                        <p className="text-[#D9D9D9] text-[12px] font-[Raleway] text-center ">(Unlimited buffet with Cocktails)</p>
                                    </div>
                                    <p className="font-[Poppins] text-white text-[18px] font-semibold">₹ 2999</p>
                                </div>
                            </div>
                        </div>

                        <div className="bg-white sm:w-3/4 w-full relative">
                            <div className="flex flex-row items-center justify-between pl-12">
                                <div className="mt-7">
                                    <h5 className="font-[Poppins] font-semibold text-[18px] text-[#57595A] tracking-[15%] uppercase">Happening on Feb 9, 2025</h5>
                                    <h3 className="font-[Raleway] text-[28px] font-semibold tracking-[4%] bg-purple text-white px-3 w-fit uppercase mt-7">Shenergy</h3>
                                    <h3 className="font-[Raleway] text-[34px] font-semibold tracking-[4%] w-[411px] uppercase mt-2">Coimbatore's Biggest Netwroking Event</h3>
                                </div>
                                <img src={event_image} alt="event_image" className="w-[196px] sm:block hidden" />
                            </div>

                            <div className="flex-none sm:flex sm:flex-row items-center pl-12 mt-9">
                                <div className="flex flex-col w-full">
                                    <h4 className="font-[Poppins] font-semibold text-[16px] tracking-[8%] leading-[22px]">OVERVIEW</h4>

                                    <hr className="border-t-[1px] border-[#8B8B8C] w-[85%] mt-1 " />
                                    
                                    <p className="font-[Raleway] mt-3 text-[16px] leading-[26px] font-normal w-[429px]">This isn’t just a celebration—it’s a launchpad for your next big opportunity. Whether you’re here to connect, be inspired, or just soak up the good vibes, this event is for <b>YOU</b>.</p>
                                    {/* <div className="flex flex-row items-center gap-x-3 mt-2">
                                        <img src={purple_percentage} alt="purple_percentage" className="w-[30px] h-[30px]" />
                                        <p className="font-[Poppins] text-purple font-semibold text-[14px] ">Early bird offer ends by 31-Dec-2024</p>
                                    </div> */}
                                </div>

                                {/* <img src={attendees} alt="250+ attendees" className="w-[147px] h-[116px] mr-9 sm:block hidden" /> */}
                                
                            </div>

                            
                            <div className="flex flex-row items-center gap-x-16 pl-12 mt-7">
                                <div className="flex flex-row items-center gap-x-3">
                                    <img src={map_pin} alt="map_pin" className="w-[40px] h-[40px]" />

                                    <div>
                                        <h1 className="font-[Raleway] font-semibold text-[16px] leading-[26px]">The Living Room </h1>
                                        <h3 className="font-[Raleway] font-normal leading-[22px] text-[14px] text-[#8B8B8C]">Nava India, Coimbatore</h3>
                                    </div>
                                </div>

                                <hr className="border-t-[0.8px] border-[#8B8B8C] rotate-[-90deg] w-[40px]" />

                                <div className="flex flex-row items-center gap-x-5">
                                    <img src={time} alt="time" className="w-[40px] h-[40px]" />

                                    <div>
                                        <h1 className="font-[Raleway] font-semibold text-[16px] leading-[26px]">Timing</h1>
                                        <h3 className="font-[Raleway] font-normal leading-[22px] text-[14px] text-[#8B8B8C]">11:00 AM - 04:00 PM</h3>
                                    </div>
                                </div>

                            </div>

                            <Link to="/event-register">
                                <div className="absolute bottom-0 text-white bg-purple w-full h-[45px] flex flex-row justify-center items-center gap-x-7 cursor-pointer " onClick={Top}>
                                    <button className="text-white font-[Poppins] text-[22px] font-medium uppercase tracking-[2px]">Register Now</button>
                                    <img src={arrow} alt="right-arrow" />
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* Event Information - Mobile View */}
            <div id="event_info" className="w-full overflow-hidden sm:hidden block bg-grey pb-12">
                <img src={event_image1} alt="event-image" className="w-[440px] h-[227px] object-cover" />

                <div className="px-5">
                    <h5 className="font-[Poppins] font-semibold text-[18px] mt-5 text-[#57595A] tracking-[15%] uppercase">Happening on <span className="font-bold">Feb 9, 2025</span></h5>
                    <h3 className="font-[Raleway] text-[24px] font-semibold tracking-[4%] bg-purple text-white px-3 w-fit uppercase mt-7">Shenergy</h3>
                    <h3 className="font-[Raleway] text-[30px] font-semibold tracking-[4%] w-[410px] uppercase mt-2">COIMBATORE'S BIGGEST NETWORKING EVENT</h3>

                    <h4 className="font-[Poppins] font-semibold text-[16px] tracking-[8%] leading-[22px] mt-9">OVERVIEW</h4>

                    <hr className="border-t-[1px] border-[#8B8B8C] w-[100%] mt-3 " />
                                    
                    <p className="font-[Raleway] mt-3 text-[16px] leading-[26px] font-normal ">This isn’t just a celebration—it’s a launchpad for your next big opportunity. Whether you’re here to connect, be inspired, or just soak up the good vibes, this event is for <b>YOU</b>.</p>

                    {/* <div className="flex flex-row items-center gap-x-3 mt-12 justify-center">
                        <img src={purple_percentage} alt="purple_percentage" className="w-[30px] h-[30px]" />
                        <p className="font-[Poppins] text-purple font-semibold text-[14px] ">Early bird offer ends by 31-Dec-2024</p>
                    </div> */}

                    <div className="flex flex-row items-center justify-between mt-3">
                            <div className="flex flex-col gap-y-3 ">
                                    <div className="flex flex-col gap-y-1 ">
                                        <p className="text-[18px] text-black font-[Poppins] text-center font-semibold">Food Only</p>
                                        <p className="text-[#8B8B8C] text-[12px] font-[Raleway] text-center ">(Unlimited buffet for 3 hrs)</p>
                                    </div>
                                    <p className="font-[Poppins] text-purple text-[18px] font-bold text-center">₹ 1499</p>
                            </div>
                            
                            <hr className="border-t-[1px] border-[#8B8B8C] w-[40px] rotate-[-90deg] " />

                            <div className="flex flex-col gap-y-3">
                                    <div className="flex flex-col gap-y-1">                            
                                        <p className="text-[18px] text-black font-[Poppins] text-center font-semibold">Food + Cocktails </p>
                                        <p className="text-[#8B8B8C]  text-[12px] font-[Raleway] text-center ">(Unlimited buffet with Cocktails)</p>
                                    </div>
                                    <p className="font-[Poppins] text-purple text-[18px] font-bold text-center">₹ 2999</p>
                            </div>
                        </div>

                        <div className="flex flex-row items-center gap-x-3 mt-12">
                            <img src={map_pin} alt="map_pin" className="w-[40px] h-[40px]" />

                            <div>
                                <h1 className="font-[Raleway] font-semibold text-[16px] leading-[26px]">The Living Room</h1>
                                <h3 className="font-[Raleway] font-normal leading-[22px] text-[14px] text-[#8B8B8C]">Nava India, Coimbatore</h3>
                            </div>
                        </div>

                        <div className="flex flex-row items-center gap-x-5 mt-7">
                            <img src={time} alt="time" className="w-[40px] h-[40px]" />

                            <div>
                                <h1 className="font-[Raleway] font-semibold text-[16px] leading-[26px]">Timing</h1>
                                <h3 className="font-[Raleway] font-normal leading-[22px] text-[14px] text-[#8B8B8C]">11:00 AM - 04:00 PM</h3>
                            </div>
                        </div>

                        <Link to="/event-register">
                            <div className="text-white bg-purple w-full h-[52px] flex flex-row justify-center items-center gap-x-7 cursor-pointer mt-12 " onClick={Top}>
                                    <button className="text-white font-[Poppins] text-[20px] font-medium uppercase tracking-[10%]">Register Now</button>
                                    <img src={arrow} alt="right-arrow" />
                            </div>
                        </Link>
                            

                </div>

            </div>



            {/* <Event_Form /> */}

            
        </Layout>
    )
}

function Top() {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
}

export default Event_Page;